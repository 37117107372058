<template>
  <div class="layout-root">
    <div
      v-if="theme === 'explore'"
      class="background"
      @click="navigateToParentDashboard"
    />

    <template v-if="selectedPeriod.dateRange.length === 2">
      <div
        v-if="!isEditing"
        class="layout-root__actions"
      >
        <AnalyticsWrapperSelectDateRange />
        <AnalyticsDimensionFiltersButton class="actions__dimension-filters" />
        <AnalyticsScopeToggle />
      </div>

      <slot />
    </template>
  </div>
</template>

<script lang="ts">
import { mapState, mapActions } from 'pinia'
import type { SlotsType } from 'vue'
import { ScopeType } from '~/types/permissions'
import { useLayoutStore } from '~/stores/layout'
import { useFiltersStore } from '~/stores/filters'
import { type Filter } from '~/types/analytics'
import { useScopeStore } from '~/stores/scope'
import { useCubeStore } from '~/stores/cube'
import { getDimensionFromTitle } from '~/services/explore'
import { navigateToParentDashboard } from '~/services/router'
import { useTimeDimensionStore } from '~/stores/timeDimension'
import { TimeDimensionStoreContext } from '~/types/timeDimension'
import { QUERY_PARAMETERS } from '~/types/queryParameters'

export default {
  name: 'DashboardLayout',
  slots: Object as SlotsType<{
    default: {}
  }>,
  setup() {
    return { navigateToParentDashboard }
  },
  computed: {
    ...mapState(useLayoutStore, ['theme', 'isEditing']),
    ...mapState(useTimeDimensionStore, ['selectedPeriod'])
  },
  async created() {
    const { loadDashboardDefaultFilters } = useFiltersStore()
    await loadDashboardDefaultFilters()
    await this.generateSelectedScopeType()
    const { loadMeta } = useCubeStore()
    await loadMeta()
    await this.generateSelectedDimensionFilters()
    await useTimeDimensionStore().generateSelectedDateRange(
      TimeDimensionStoreContext.DASHBOARD
    )
  },
  beforeUnmount() {
    // Resetting all the analytics stores
    useScopeStore().$reset()
    useFiltersStore().$reset()
  },
  methods: {
    ...mapActions(useFiltersStore, [
      'prepareDashboardDefaultFilters',
      'cleanDefaultFilterIdsToRemove',
      'setCustomFilters',
      'toggleFilters'
    ]),
    async generateSelectedDimensionFilters() {
      const route = useRoute()
      if (!route.query[QUERY_PARAMETERS.DASHBOARD_FILTERS]) {
        await this.setCustomFilters({
          values: [],
          shouldModifyUrlParams: false
        })
        return
      }

      try {
        const customFilters = JSON.parse(
          decodeURIComponent(
            route.query[QUERY_PARAMETERS.DASHBOARD_FILTERS] as string
          )
        ) as Filter[]

        if (customFilters.length === 0) {
          throw new Error('bad filters')
        }

        customFilters.forEach(customFilter => {
          if (
            !('member' in customFilter) ||
            !('operator' in customFilter) ||
            !('values' in customFilter)
          ) {
            throw new Error('bad filters')
          }

          const dimension = getDimensionFromTitle(customFilter.member)

          if (!dimension) {
            throw new Error('bad filters')
          }
        })

        await this.setCustomFilters({
          values: customFilters,
          shouldModifyUrlParams: false
        })

        setTimeout(() => {
          this.toggleFilters()
        }, 500)
      } catch (error) {
        await this.setCustomFilters({
          values: [],
          shouldModifyUrlParams: true
        })
      }
    },
    async generateSelectedScopeType() {
      const route = useRoute()

      const scopeType = route.query[
        QUERY_PARAMETERS.DASHBOARD_SCOPE_TYPE
      ] as string
      const { setSelectedScopeType } = useScopeStore()

      if (
        !scopeType ||
        !Object.values(ScopeType).includes(scopeType as ScopeType)
      ) {
        await setSelectedScopeType()
        return
      }

      await setSelectedScopeType(scopeType as ScopeType, false)
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  padding-top: $margin-regular;
  padding-bottom: $margin-medium;

  &__actions {
    z-index: 3;
    position: absolute;
    right: calc(50% - $page-width / 2);
    height: 54px;
    display: flex;
    align-items: center;
    gap: $margin-small;
  }
}

.background {
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100%;
  width: calc(100% + $side-width);
  background-color: $overlay-background;
  cursor: pointer;
}
</style>
